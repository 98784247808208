import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 8-8-8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`1/4 Mile Run`}</p>
    <p>{`21-Deadlifts (225/155)`}</p>
    <p>{`42-Wall Balls (20/14)`}</p>
    <p>{`15-Deadlifts`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`9-Deadlifts`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`1/4 Mile Run`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      